import React, { Component } from 'react';

import { ajax_wrapper, sort_objects } from 'functions';

class Select extends Component {
    static component_name = 'Select';

    constructor(props) {
        super(props);
        this.state = { options: [] };

        this.refresh_data = this.refresh_data.bind(this);
        this.options_callback = this.options_callback.bind(this);
        this.handle_change = this.handle_change.bind(this);
    }

    componentDidMount() {
        this.refresh_data();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.options_url !== prevProps.options_url) {
            this.refresh_data();
        }
    }

    refresh_data() {
        if (this.props.options_url && this.props.options_url !== '') {
            ajax_wrapper(
                'GET',
                this.props.options_url,
                {},
                this.options_callback.bind(this),
            );
        }
    }

    options_callback(value) {
        const options = [];
        for (const index in value) {
            options.push({
                text: value[index]['name'],
                value: value[index]['id'],
            });
        }

        sort_objects(options, ['text']);

        this.setState({ options });
    }

    handle_change = (e) => {
        const selection = e.target.value;
        const new_state = {};
        new_state[this.props.name] = selection;

        this.props.set_form_state(new_state);
    };

    render() {
        let label = null;
        if (this.props.label) {
            label = (
                <label style={this.props.label_style}>{this.props.label}</label>
            );
        }

        let value = this.props.value;

        const option_dict = this.props.options_url
            ? this.state.options
            : this.props.options;
        const options = [];

        // Render Select Component
        if (!this.props.no_blank_option) {
            let selected = value ? {} : { selected: 'selected' };
            options.push(<option key={-1} value="" {...selected} />);
        }

        // Create JSX for select options
        for (let index in option_dict) {
            options.push(
                <option key={index} value={String(option_dict[index].value)}>
                    {option_dict[index].text}
                </option>,
            );
        }

        return (
            <div
                className={`form-group ${this.props.className}`}
                style={this.props.style}
            >
                {label}
                <div>
                    <select
                        className="form-control"
                        name={this.props.name}
                        onChange={this.handle_change}
                        value={value}
                    >
                        {options}
                    </select>
                </div>
            </div>
        );
    }
}

export default Select;

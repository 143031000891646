import React, { Component } from 'react';

import { Loading, Alert, Button } from 'library';
import { UserValidator, ajax_wrapper, get_global_state } from 'functions';

export default class DropboxExplorer extends Component {
    constructor(props) {
        super(props);

        let checker = new UserValidator();

        this.state = {
            loaded: false,
            dropbox_creds: checker.dropbox_creds(),

            dropbox_entries: [],

            selected_folder: [],
            selected_files: [],
        };

        this.search = this.search.bind(this);
        this.load_search = this.load_search.bind(this);
        this.go_back_folder = this.go_back_folder.bind(this);
        this.toggle_file = this.toggle_file.bind(this);
        this.assign_all = this.assign_all.bind(this);
        this.unassign_all = this.unassign_all.bind(this);

        this.update_form = this.update_form.bind(this);
        this.start_dropbox_auth = this.start_dropbox_auth.bind(this);
    }

    componentDidMount() {
        let params = get_global_state()['params'];


        if (params['id'] !== undefined) {

            ajax_wrapper('GET', `/api/home/project/${params['id']}/`, {}, (value) =>
                this.setState({ loaded: true, data: value }),
            );
        } else {
            this.setState({ loaded: true });
        }

        if (this.state.dropbox_creds) {
            this.search();
        }
    }

    load_project(value) {
        this.setState({
            data: value,
        });
    }

    search() {
        this.setState(
            {
                loaded: false,
            },
            function () {
                ajax_wrapper(
                    'GET',
                    '/api/dropbox_search/?folder=' +
                        encodeURIComponent(
                            this.state.selected_folder.join('/'),
                        ),
                    {},
                    this.load_search,
                );
            }.bind(this),
        );
    }

    load_search(value) {
        if ('error' in value) {
            this.setState({
                loaded: true,
                error: value['error'],
                dropbox_creds: null,
            });
        } else {
            this.setState({ loaded: true, dropbox_entries: value });
        }
    }

    start_dropbox_auth() {
        localStorage.setItem('redirect', this.props.redirect_url);
        window.location.href = 'user/dropbox_auth/';
    }

    go_back_folder() {
        let selected_folder = this.state.selected_folder.slice(
            0,
            this.state.selected_folder.length - 2,
        );
        this.setState(
            {
                selected_folder: selected_folder,
            },
            this.search,
        );
    }

    toggle_file(file) {
        let new_files = this.state.selected_files;

        if (this.state.selected_files.includes(file)) {
            let new_files = this.state.selected_files;
            var index = new_files.indexOf(file);
            if (index !== -1) {
                new_files.splice(index, 1);
            }
        } else {
            new_files.push(file);
        }

        this.setState({ selected_files: new_files }, this.update_form);
    }

    assign_all() {
        let all_files = [];
        for (let item of this.state.dropbox_entries) {
            if (item.type !== 'folder') {
                all_files.push(item.name);
            }
        }

        this.setState({ selected_files: all_files }, this.update_form);
    }

    unassign_all() {
        this.setState({ selected_files: [] }, this.update_form);
    }

    update_form() {
        let new_state = {};
        new_state[this.props.files_name] = this.state.selected_files;
        new_state[this.props.folder_name] = this.state.selected_folder;
        this.props.set_form_state(new_state);
    }

    render() {
        let folders = [];
        let files = [];

        for (let item of this.state.dropbox_entries) {
            if (item.type === 'folder') {
                folders.push(
                    <Button
                        style={{ display: 'block' }}
                        type="outline-secondary"
                        onClick={() =>
                            this.setState(
                                {
                                    selected_folder: [
                                        ...this.state.selected_folder,
                                        item.name,
                                    ],
                                    selected_files: [],
                                },
                                function () {
                                    this.update_form();
                                    this.search();
                                },
                            )
                        }
                    >
                        {item.name}
                    </Button>,
                );
            } else {
                let active = '';
                if (this.state.selected_files.includes(item.name)) {
                    active = 'active';
                }

                files.push(
                    <Button
                        style={{ display: 'block' }}
                        type="outline-primary"
                        onClick={() => this.toggle_file(item.name)}
                        className={`dropbox-file ${active}`}
                    >
                        {item.name}
                    </Button>,
                );
            }
        }

        let dropbox_error = null;
        if (this.state.error) {
            dropbox_error = <Alert type="danger">{this.state.error}</Alert>;
        }

        let dropbox_auth = null;
        if (!this.state.dropbox_creds || this.state.dropbox_creds === '') {
            dropbox_auth = (
                <Button type="primary" onClick={this.start_dropbox_auth}>
                    Sync With Dropbox
                </Button>
            );
        }

        let back_button = null;
        if (this.state.selected_folder.length > 0) {
            back_button = (
                <Button
                    style={{ marginLeft: '10px' }}
                    type="warning"
                    onClick={this.go_back_folder}
                >
                    Go Back
                </Button>
            );
        }

        return (
            <div style={{ position: 'relative' }}>
                <Loading loaded={this.state.loaded} cover={true}>
                    <div className="simple-card">
                        {dropbox_error}

                        {dropbox_auth}

                        <h5>
                            Current Folder:{' '}
                            {this.state.selected_folder.join('/')}
                        </h5>

                        <p>
                            Click through your dropbox folders below until the
                            current folder above shows the folder with the songs
                            you want to import.
                        </p>
                    </div>
                    <div className="row" style={{ margin: '0px' }}>
                        <div className="col-6 ">
                            <div className="simple-card">
                                <div style={{ marginBottom: '10px' }}>
                                    <h4
                                        style={{
                                            display: 'inline-block',
                                            margin: '0px',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        Files
                                    </h4>
                                    <Button
                                        onClick={this.assign_all}
                                        type="primary"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Assign All
                                    </Button>
                                    <Button
                                        onClick={this.unassign_all}
                                        type="danger"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Unassign All
                                    </Button>
                                </div>
                                {files}
                            </div>
                        </div>
                        <div className="col-6 ">
                            <div className="simple-card">
                                <div style={{ marginBottom: '10px' }}>
                                    <h4
                                        style={{
                                            display: 'inline-block',
                                            margin: '0px',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        Folders
                                    </h4>
                                    {back_button}
                                </div>
                                {folders}
                            </div>
                        </div>
                    </div>
                </Loading>
            </div>
        );
    }
}

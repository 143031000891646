import React, { Component } from 'react';

import { Form, TextInput, Container, Loading, Button } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

class TagType extends Component {
    render() {
        let tags = [];
        for (let item of this.props.data['tags']) {
            tags.push(<div>{item['name']}</div>);

            for (let child of item['children']) {
                tags.push(
                    <div style={{ paddingLeft: '15px' }}>{child['name']}</div>,
                );
            }
        }

        return (
            <div className="simple-card">
                <h6>{this.props.data['name']}</h6>
                <div>{tags}</div>
            </div>
        );
    }
}

export default class ManageTaxonomy extends Component {
    constructor(props) {
        super(props);
        let params = get_global_state()['params'];

        let url = `/api/home/taxonomy/${params['id']}/`;

        this.state = {
            url: url,
            loaded: false,
            data: null,
            tags: [],
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper('GET', this.state.url, {}, (value) =>
            this.setState({ loaded: true, data: value }),
        );

        if (params['id']) {
            ajax_wrapper(
                'GET',
                `/api/taxonomy_tags/${params['id']}/`,
                {},
                (value) => this.setState({ loaded: true, tags: value }),
            );
        }
    }

    render() {
        let params = get_global_state()['params'];

        let tag_types = [];
        for (let item of this.state.tags) {
            tag_types.push(<TagType data={item} />);
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <Form
                        defaults={this.state.data}
                        submit_url={this.state.url}
                        delete_url={this.state.url}
                        redirect={function () {
                            window.location.href = '/admin/home/taxonomy/';
                        }}
                    >
                        <TextInput name="name" label="name" />
                    </Form>

                    {params['id'] ? (
                        <div>
                            <Button
                                type="primary"
                                href={`/admin/exportformat/?taxonomy=${params['id']}`}
                            >
                                Add Export Format
                            </Button>
                            <Button
                                type="primary"
                                href={`/admin/uilayout/?taxonomy=${params['id']}`}
                            >
                                Add UI Layout
                            </Button>
                            <Button
                                type="primary"
                                href={`/admin/mlmodel/?taxonomy=${params['id']}`}
                            >
                                Add ML Model
                            </Button>
                        </div>
                    ) : null}
                    <div>
                        <h4>Tag Types</h4>
                        <div className="simple-card-container">{tag_types}</div>
                    </div>
                </Loading>
            </Container>
        );
    }
}

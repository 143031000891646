import React, { Component } from 'react';

import { Container, Loading, Button, DeleteButton } from 'library';
import { ajax_wrapper, get_global_state, sort_objects, toast_handler } from 'functions';
import { SongCard, DynamicSearch } from 'components';
import $ from 'jquery';

function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.project-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

export default class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: { songs: [], tags: [] },
            editing_track_id: '',
            show_search: false,
            search_type: '',
            search_type_id: '',
            tag_lookup: {},
            song_tags: []
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'GET',
            `/api/project_details/${params['id']}/`,
            {},
            (value) => { 
                let song_tags = []
                for (let song of value.songs) {
                    song_tags.push([song.tags]);
                }
                this.setState({ loaded: true, data: value, song_tags: song_tags }, 
                this.load_tags(value['taxonomy_tags']));
            }
        );
    }

    delete_project = () => {
        ajax_wrapper(
            'POST',
            `/api/delete_project/${this.state.data.id}/`,
            {},
            (value) => (window.location = `/`),
        );
    };

    load_tags = (value) => {
        let tag_lookup = {};

        for (let tagtype of value) {
            tag_lookup[tagtype['id']] = tagtype;
            for (let item of tagtype['tags']) {
                tag_lookup[item['id']] = item;
                for (let child of item['children']) {
                    tag_lookup[child['id']] = child;
                }
            }
        }
        console.log('tag_lookup', tag_lookup);
        this.setState({
            tags: [],
            tag_lookup: tag_lookup,
        });
    };

    toggle_song_tag = (id) => {
        let song_id = this.state.editing_track_id;
        let song_index = this.state.data.songs.findIndex(
                            (song) => {return song.id === song_id});

        let data = {
            add: [],
            remove: [],
        };

        let found_tag = null;
        for (let item of this.state.tags) {
            if (item['id'] == id) {
                found_tag = this.state.tags.indexOf(item);
            }
        }
        if (found_tag == null) {
            data['add'].push(id);
            this.state.tags.push(this.state.tag_lookup[id]);
            this.state.data.songs[song_index].tags.push(this.state.tag_lookup[id]);
        } else {
            data['remove'].push(id);
            this.state.tags.splice(found_tag, 1);
            this.state.data.songs[song_index].tags.splice(found_tag, 1);
        }

        sort_objects(this.state.tags, ['name']);


        this.setState(
            {
                tags: this.state.tags,
            },
            () =>
                ajax_wrapper(
                    'POST',
                    `/api/toggle_song_tags/${song_id}/`,
                    data,
                    (value) => toast_handler(value, null),
                ),
        );
    };

    show_search = (type_id, search_type, track_id, track_tags, force_update) => {

        this.setState({
            tags: track_tags,
            search_type: search_type,
            search_type_id: type_id,
            editing_track_id: track_id,
            update_trigger: force_update,
            show_search: true
        });
    }

    export_project = () => {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'GET',
            `/api/project_export/${params['id']}/`,
            {},
            (value) => {
                const element = document.createElement('a');
                const file = new Blob([value['text']], { type: 'text/csv' });
                element.href = URL.createObjectURL(file);
                element.download = value['filename'];
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            },
        );
    };

    render() {
        let params = get_global_state()['params'];

        let songs = [];
        for (let song of this.state.data['songs']) {
            songs.push(
                <SongCard
                    editable={true}
                    data={song}
                    show_search={this.show_search}
                />,
            );
        }

        return (
            <div className="project-page-container">
                <Container>
                    <Loading loaded={this.state.loaded}>
                        <DynamicSearch
                            show={this.state.show_search}
                            tag_type_name={this.state.search_type}
                            tag_type={this.state.search_type_id}
                            data={this.state.data}
                            project_view={true}
                            tags={this.state.tags}
                            tag_lookup={this.state.tag_lookup}
                            toggle_tag={this.toggle_song_tag}
                            on_hide={() =>
                                this.setState(
                                    {
                                        show_search: false,
                                        search_type: '',
                                        search_type_id: '',
                                    },
                                    focus_on_page,
                                )
                            }
                        />

                        <div className="simple-card-container">
                            <div className="simple-card">
                                <h1>{this.state.data.name}</h1>
                            </div>

                            <div className="simple-card">

                            <h3>Project Notes:</h3>
                            <p>{this.state.data.project_notes}</p>

                            </div>
                            <div className="simple-card">
                                <div>
                                    <DeleteButton
                                        className="project-button"
                                        type="danger"
                                        url="/api/delete_project/"
                                        callback={() => window.location='/'}
                                        value={this.state.data.id}
                                    >
                                        Delete Project
                                    </DeleteButton>
                                    <Button
                                        style={{ marginRight: '10px' }}
                                        className="project-button"
                                        type="primary"
                                        onClick={this.export_project}
                                    >
                                        Export CSV
                                    </Button>
                                    <Button
                                        style={{ marginRight: '10px' }}
                                        className="project-button"
                                        type="primary"
                                        href={`/project/${params['id']}/add_songs/`}
                                    >
                                        Add Songs
                                    </Button>
                                </div>

                                <h3>Total Tracks: {songs.length}</h3>
                                To Be Tagged:
                                <br />
                                In Progress:
                                <br />
                                Complete:
                            </div>
                            {songs}
                        </div>
                    </Loading>
                </Container>
            </div>
        );
    }
}

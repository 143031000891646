import React, { Component } from 'react';
import { Button } from 'library';

export default class EmptyProjectsDisplay extends Component {

    render() {


        return(
        	<div style={{paddingBottom: '10px'}}>
            	<h3>You have no active projects!</h3>
            	<i>—> Select from the following —> </i><br/><br/>
            	<Button type="primary" className="dark-button" onClick={this.props.new_project}>Create a new project</Button><br/>
            	<Button type="primary" className="dark-button" href='#admin-projects'>View active projects</Button><br/>
            	<Button type="primary" className="dark-button" href='admin/manage_taggers'>Manage Taggers</Button><br/>
            </div>

            )
    }

}
import React, { Component } from 'react';
import { TagButton } from 'components';


export default class TagDropdowns extends Component {

	constructor(props) {
        super(props);
        this.state = {
            parents_showing: {}
        }

        this.setupTags();

    }

    setupTags = () => {

        let parent_tags = {};
        let child_tags = [];
        let parent_children_jsx = [];


        for (let tag of this.props.tags) {

            if (tag.parent === null) {
                parent_tags[tag.id] = tag.name;
            }

            if (tag.selectable) {
                child_tags.push(tag);
            }
        }


    }




    render () {

        let parent_tags = {};
        let child_tags = [];
        let parent_children_jsx = [];


        for (let tag of this.props.tags) {

            if (tag.parent === null) {
                parent_tags[tag.id] = tag.name;
            }

            if (tag.selectable) {
                child_tags.push(tag);
            }
        }



        if (Object.keys(parent_tags).length === 0) {
            for (let tag of child_tags) {
                parent_children_jsx.push(
                    <TagButton
                        data={tag}
                        active={this.props.song_tags.includes(tag['id']) ? 'active' : ''}
                        toggle_tag={this.props.toggle_tag}
                    />
                );

            }
        }


        for (let tag of child_tags) {
            parent_children_jsx.push(

                <TagButton
                    data={tag}
                    active={this.props.song_tags.includes(tag['id']) ? 'active' : ''}
                    toggle_tag={this.props.toggle_tag}
                />


            )

        }

    	return (
    		<div>{parent_children_jsx}</div>

    		)


    }


}
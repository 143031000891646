import React, { Component } from 'react';
import { Button } from 'library';

export default class TagButton extends Component {
    render() {
        let tag = this.props.data;
        let content = tag['name'];

        if (this.props.parent_text) {
            content = (
                <div>
                    <div
                        style={{
                            float: 'right',
                            color: 'rgba(255,255,255,.4)',
                        }}
                    >
                        {this.props.parent_text}
                    </div>
                    <div style={{ float: 'left' }}>{tag['name']}</div>
                </div>
            );
        }

        return (
            <Button
                type="outline-primary"
                className={`song_tag ${this.props.active}`}
                style={{ paddingLeft: '15px', margin: '4px' }}
                onClick={() => this.props.toggle_tag(tag['id'])}
            >
                {content}
            </Button>
        );
    }
}

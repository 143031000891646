import React, { Component } from 'react';
import $ from 'jquery';
import { ajax_wrapper } from 'functions';
import { CreateSync } from 'pages'
import { Button, Modal, TextInput, TextArea,
         Select, MultiSelect, Form, ItemPool } from 'library';



export class NewSplitCard extends Component {

    render() {
        return (
            <div>
                <p style={{paddingLeft: "10px", 
                            paddingTop: "10px", 
                            marginBottom: "2px",
                            fontSize: "14px",
                            fontStyle: "italic"}} >Add New Split...</p>
                <div className="simple-card project-card flex-container"
                        style={{width: '80px', marginTop: '0px'}}>

                    <Button 
                        type="primary" 
                        onClick={this.props.click}
                        className="dark-button new-project-button">
                            +
                    </Button>
                </div>
            </div>
        );
    }
}


export default class NewProject extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {

            project_name: '',
            export_format: 'TTA Default',
            taggers: [],
            client_id: '',
            taxonomy_id: '',
            splits_display: 'none',
            sync_display: 'none',
            taxonomy_tagtypes_lookup: {},
            timestamp: new Date().getTime(),
            splits: [{number: 1, tag_types: []}],
            tag_type_options: []
        };

        for (let taxonomy of this.props.data.context.taxonomies) {
            this.state.taxonomy_tagtypes_lookup[taxonomy.id] = taxonomy.tag_types;
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.taxonomy_id !== '' && this.state.taxonomy_id !== prevState.taxonomy_id) {
            this.setState({splits_display: "block"});
            this.setState({tag_type_options: this.state.taxonomy_tagtypes_lookup[this.state.taxonomy_id]})
        }

    }

    submit(state) {

        let url = `/api/create_project/`;
        let data = {

            name: state.project_name,
            client_id: state.client_id,
            taxonomy_id: state.taxonomy_id,
            taggers: state.taggers,
            tag_types: state.tag_types,
            project_notes: state.project_notes


        };

        ajax_wrapper(
            'POST',
            url,
            data,
            (value) => {
                this.props.on_hide();
                this.props.callback(value);
                this.setState({timestamp: Date.now()});
            }
        );
    }

    createNewSplit = () => {



    }



    render() {

        let context = this.props.data.context

        let export_formats = []
        let client_options = []
        let taxonomy_options = []
        let tagger_options = []
        let tag_types = []

        for (let index in this.props.data.export_formats) {
            export_formats.push({text: this.props.data.export_formats[index], value: 'dummy-id'});
        };

        for (let client of context.clients) {
            client_options.push({text: client['name'], value:client.id});
        }

        for (let taxonomy of context.taxonomies) {
            taxonomy_options.push({text: taxonomy['name'], value:taxonomy.id});
        }

        for (let tagger of this.props.data.context.taggers) {
            tagger_options.push({text: tagger.name, value: tagger.id})
        }

        if (this.state.taxonomy_id !== '') {
            for (let tag_type of this.state.tag_type_options) {
                tag_types.push({text: tag_type.name, value: tag_type.id})
            }
        }

        let max_width = '50%';
        let min_width = '50%';

        if (this.state.splits.length !== 1) {

            let min_width = (100 / this.state.split_count) + '%';
            let max_width = (100 / this.state.split_count) + '%';
        }


        return (

            <div key={this.state.timestamp}>
                <Modal
                    show={this.props.show}
                    on_hide={this.props.on_hide}
                >

                <div className="simple-card-container modal-bounding-box">
                    <Form
                        set_global_state={(state_name, state) => this.setState(state)}
                        auto_set_global_state={true}
                        submit={this.submit}
                        submit_text="Create New Project"
                    >
                    <Button />

                        <table>
                            <tr>
                                <td><h2>Create New Project</h2></td>
                                <td width="55%">
                                    <TextInput
                                        name="project_name"
                                        className="new-project-name"
                                        style={{color: 'white',
                                                marginLeft: '10px', 
                                                backgroundColor: 'rgba(0,0,0,0)',
                                                fontSize: '24px',
                                                borderTop: '0px',
                                                borderRight: '0px',
                                                borderLeft: '0px',
                                                borderRadius: '0px'}}
                                        required={true}
                                        placeholder="Project..."
                                    />
                                </td>
                                <td style={{paddingLeft: "18px"}}><MultiSelect
                                        name="export_format"
                                        value="{this.state.export_format}"
                                        options={export_formats}
                                    />

                                </td>
                            </tr>
                        </table>

                        <table style={{
                                    width: "100%"
                                }}>
                            <tr>
                                <td style={{verticalAlign: 'top', }}>
                                    <Select 
                                        name="client_id"
                                        label="Client"
                                        options={client_options} />
                                </td>
                                <td style={{width: "50%", paddingLeft: '20px'}}>
                                    <TextArea 
                                        name="project_notes"
                                        label="Project Notes:" />
                                </td>
                            </tr>

                        </table>

                                    


                        <table style={{
                                    maxWidth: max_width,
                                    minWidth: min_width
                                }}>
                            <tr>
                                <td>
                                    <Select
                                        name="taxonomy_id"
                                        label="Taxonomy"
                                        options={taxonomy_options} />
                                </td>
                            </tr>
                            <br />
                        <div style={{display: this.state.splits_display}}>
                            <h3>Split 1</h3>
                            <MultiSelect
                                style={{fontStyle: "bold"}}
                                name="taggers"
                                label="Taggers:"
                                options={tagger_options} />

                            <tr>
                                <td>
                                    <MultiSelect
                                        style={{maxWidth: {max_width},
                                            minWidth: {min_width}}}
                                        multiple={true}
                                        name="tag_types"
                                        label = 'Fields:'
                                        item_pool = {true}
                                        options={tag_types}
                                        />
                                </td>
                                <td>
                                    <NewSplitCard click={this.createNewSplit}/>
                                </td>
                            </tr>
                        </div>


                        </table>
                        <div style={{marginTop: "5px"}} />
                        
                            

                        

                    </Form>

                    <Button 
                        type="primary" 
                        className="dark-button"
                        style={{fontStyle: "italic", marginTop: "6px", opacity: "90%"}}
                        onClick={() => this.setState({sync_display: 'block'})}
                        >
                        Add Songs
                    </Button>

                    <div style={{display: this.state.sync_display}}>

                        <CreateSync />
                    </div>

                </div>

                </Modal>


            </div>
            )

    }

}
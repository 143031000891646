import React, { Component } from 'react';

import { Button, Image } from 'library';

export default class ProjectCard extends Component {
    render() {
        let data = this.props.data;
        let project_data = data;
        let taggers_jsx = [];

        if (this.props.taggers) {

            taggers_jsx.push(<h5>Taggers:</h5>)

            for (let tagger of this.props.taggers) {

                taggers_jsx.push(<span className="multiselect-selection">{tagger.name}</span>);
            }
        }

        return (
            <div className="simple-card project-card col-3">
                <h4>{project_data['name']}</h4>
                <p className="project-card-subtitle">
                    Client:  {project_data['client_name']}
                </p>

                {taggers_jsx}

                <div className="project-card-text">
                    Total Tracks: {project_data['total_tracks']}
                    <br/>
                    Tracks Complete: {project_data['tracks_complete']}
                    <br/>
                    Tracks Remaining: {project_data['tracks_remaining']}
                </div>
                <br/>
                <Button type="primary" href={`/project/${data['id']}/`}>
                    View
                </Button>
                <Image
                    src="/static/images/download-icon-medium-white.png"
                    className="project-download-button"
                />
            </div>
        );
    }
}



export class NewProjectCard extends Component {
    render() {
        return (
            <div className="simple-card project-card flex-container"
                    style={{width: '80px'}}>
                <Button 
                    type="primary" 
                    onClick={this.props.newProject}
                    className="dark-button new-project-button">
                        +
                </Button>
            </div>
        );
    }
}
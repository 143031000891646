import React, { Component } from 'react';

import { ajax_wrapper, get_global_state } from 'functions';
import { Link, Container, Button, Loading } from 'library';

class Item extends Component {
    render() {
        return (
            <div className="simple-card">
                <Link
                    href={`/admin/${this.props.params['modelname']}/${this.props.data['id']}`}
                >
                    {this.props.data['name']}
                </Link>
            </div>
        );
    }
}

export default class ObjectList extends Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false, list: [] };

        this.load_list = this.load_list.bind(this);
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'GET',
            `/api/${params['app']}/${params['modelname']}/`,
            {},
            this.load_list,
        );
    }

    load_list(value) {
        this.setState({ loaded: true, list: value });
    }

    render() {
        let params = get_global_state()['params'];

        let object_list = [];
        for (let item of this.state.list) {
            object_list.push(<Item data={item} params={params} />);
        }

        let new_url = `/admin/${params['modelname']}/`;
        if (params['modelname'] === 'taxonomy') {
            new_url = `/admin/import_taxonomy/`;
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <h2>{`This page will load ${params['modelname']} model`}</h2>
                    <div>
                        <Button type="primary" href={new_url}>
                            Add New
                        </Button>
                    </div>
                    <div className="simple-card-container">{object_list}</div>
                </Loading>
            </Container>
        );
    }
}

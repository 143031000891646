import React, { Component } from 'react';
import $ from 'jquery';
import { Form, TextInput, Container, Loading, Button } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

import { TaggerCard, NewTaggerModal } from 'components';

import { ObjectList } from './object_list';


function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.admin-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}


export default class TaggerManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            taggers: [],
            users: [],
            show_new_tagger: false,
        };
    }

    componentDidMount() {

        ajax_wrapper(
            'GET',
            `/api/get_taggers_users/`,
            {},
            (value) => this.setState({ loaded: true, 
                                        taggers: value['taggers'], 
                                        users: value['users']
                                    }),
        );

        focus_on_page();
    }


    newTaggerCallback = (value) => this.setState({ taggers: value['taggers'] })

    deleteTagger = (tagger_id) => {

        ajax_wrapper(
            'POST',
            `/api/delete_tagger/${tagger_id}/`,
            {},
            (value) => this.setState({ taggers: value['taggers'] }),
        );
    }

    render() {

    	let tagger_cards = [];

        for (let item of this.state.taggers) {
            tagger_cards.push(<TaggerCard data={item} users={this.state.users} deleteSelf={this.deleteTagger} />);
        }

        return (


        <div class="admin-page-container">
            <Container>
                <Loading loaded={this.state.loaded}>
                    <NewTaggerModal 
                        data={{'users': this.state.users}}
                        show={this.state.show_new_tagger}
                        callback={this.newTaggerCallback}
                        on_hide={() => 
                                this.setState(
                                {
                                    show_new_tagger: false,
                                },
                                focus_on_page,
                            )
                        }
                    />
                    <div>
                        <h1>Active Taggers:</h1>
                        <Button type="primary" style={{display: 'inline-block'}}
                                    onClick={() => this.setState({show_new_tagger: true})}
                                >New Tagger</Button>
                            <div className="simple-card-container row">
                                {tagger_cards}
                            </div>
                    </div>
                </Loading>
            </Container>
        </div>


        
        	
        );
    }
}
import React, { Component } from 'react';

import { Container, Loading, MultiSelect, Form } from 'library';
import { UserValidator, ajax_wrapper, get_global_state } from 'functions';
import { DropboxExplorer } from 'components';

export default class CreateSync extends Component {
    constructor(props) {
        super(props);

        let checker = new UserValidator();

        this.state = {
            loaded: false,
            data: { ml_options: [] },

            is_staff: checker.is_staff(),

            selected_files: [],
            selected_folder: [],
            features: ['bpm', 'key', 'ml_analyze'],
        };

        this.load_project = this.load_project.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        console.log('ID:  ', params['id']);

        if (params['id'] !== undefined) {

            ajax_wrapper('GET', `/api/project_details/${params['id']}/`, {}, (value) =>
                this.setState({ loaded: true, data: value }),
            );
        } else {
            this.setState({ loaded: true });

        }

        if (this.state.dropbox_creds) {
            this.search();
        }
    }

    load_project(value) {
        this.setState({
            data: value,
        });
    }

    submit(state) {
        let project_id = this.state.data.id;
        var folder_name = state.selected_folder.join('/');

        let url = `/api/create_dropbox_sync/${project_id}/`;
        let data = {
            folder_name: folder_name,
            files: state.selected_files,
            bpm: state.features.includes('bpm'),
            ml_analyze: state.features.includes('ml_analyze'),
            key: state.features.includes('key'),
            ml_selections: state.ml_selections ? state.ml_selections : []
        };

        ajax_wrapper(
            'POST',
            url,
            data,
            function (value) {
                if ('error' in value) {
                    this.setState({ error: value['error'] });
                } else {
                    window.location.href = `/project/${project_id}/?dropbox_sync=true`;
                }
            }.bind(this),
        );
    }

    render() {

        console.log('DATA:  ', this.state.data)
        let ml_options = [];

        for (let ml_model of this.state.data.ml_options) {
            ml_options.push({text: ml_model.name, value: ml_model.id});
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div className="simple-card-container">
                        <div className="simple-card">
                            <div class="page-header">
                                <h1>{`Add A New Song To Project: ${this.state.data.name}`}</h1>
                            </div>
                        </div>

                        <Form
                            defaults={{
                                features: this.state.features,
                                selected_files: this.state.selected_files,
                                selected_folder: this.state.selected_folder,
                            }}
                            submit={this.submit}
                            submit_text="Import Selected Files"
                            buttons_className="simple-card"
                        >
                            <MultiSelect
                                className="simple-card"
                                checkbox
                                name="features"
                                label="Features"
                                options={[
                                    { text: 'BPM Analyzer', value: 'bpm' },
                                    { text: 'Key Finder', value: 'key' },
                                    {
                                        text: 'Machine Learning',
                                        value: 'ml_analyze',
                                    },
                                ]}
                            />

                            <MultiSelect
                                checkbox
                                name="ml_selections"
                                label="Available ML:"
                                options={ml_options}
                            />

                            <DropboxExplorer
                                files_name="selected_files"
                                folder_name="selected_folder"
                                redirect_url={`project/${this.state.data.id}/add_songs`}
                            />
                        </Form>
                    </div>
                </Loading>
            </Container>
        );
    }
}

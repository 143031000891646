import React, { Component } from 'react';
import $ from 'jquery';

import { ajax_wrapper } from 'functions';
import { ProjectCard, NewProjectCard, EmptyProjectsDisplay, NewProject} from 'components';
import { Container, Loading, Button } from 'library';


function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.song-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

export default class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            projects: [],
            admin_projects: [],
            show_project_create: false,
            tagger: {'name': ''}
        };
    }

    componentDidMount() {
        ajax_wrapper('GET', `/api/my_projects/`, {}, (value) =>
            this.setState({ loaded: true, 
                            projects: value['projects'],
                            admin_projects: value['admin_projects'], 
                            tagger: value['tagger'],
                            new_project_context: value['new_project_context'] }),
        );
    }

    newProjectWindow = () => {

        this.setState({show_project_create: true})
    }

    newProjectCallback = (value) => this.setState({ projects: value['projects'],
                                                    admin_projects: value['admin_projects'] })

    render() {
        let projects = [];
        let admin_projects = [];
        let tagger_name = this.state.tagger['name'];

        if (this.state.projects.length > 0) {
            projects.push(<NewProjectCard newProject={this.newProjectWindow} taggers={[this.state.tagger]} />);
        }

        if (this.state.admin_projects.length > 0) {
            admin_projects.push(<NewProjectCard newProject={this.newProjectWindow} />);
        }

        for (let item of this.state.projects) {
            projects.push(<ProjectCard data={item} taggers={item.taggers} />);
        }

        for (let item of this.state.admin_projects) {
            admin_projects.push(<ProjectCard data={item} taggers={item.taggers} />);
        }



        if (projects.length === 0) {
            projects.push(<EmptyProjectsDisplay new_project={this.newProjectWindow} />);
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div>
                        <NewProject
                            data={{'users': this.state.users,
                                    'taggers': this.state.taggers,
                                    'export_formats': ['TTA Default',
                                                        'Harvest'],
                                    'context': this.state.new_project_context}}
                            show={this.state.show_project_create}
                            callback={this.newProjectCallback}
                            on_hide={() => 
                                    this.setState(
                                    {
                                        show_project_create: false,
                                    },
                                    focus_on_page,
                                )
                            }
                        />
                        
                        <div>

                            <h1>Active Projects - {tagger_name}</h1>
                            <div className="simple-card-container row">

                                {projects}

                            </div>
                            <br />
                            <div id="admin-projects">
                                <h1>All Projects (Admin)</h1>

                                <div className="simple-card-container row">

                                    {admin_projects}

                                </div>
                            </div>
                        </div>
                    </div>
                </Loading>
            </Container>
        );
    }
}

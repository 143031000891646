import React, { Component } from 'react';
import { Button, TextInput } from 'library';

export default class BPM extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tic: null,
            bpm_history: [],
            accurate: false,
            manual: false,
        };
    }

    componentDidMount() {
        document.body.addEventListener('keyup', this.handle_keypress);
    }

    update = (value) => {
        this.props.update({ bpm: value });
    };

    handle_keypress = (event) => {
        if (event.keyCode == 17) {
            let tic = this.state.tic;
            if (tic) {
                let bpm_history = this.state.bpm_history;
                let toc = Date.now();
                let bpm = 60000 / (toc - tic);
                console.log('Newest BPM: ', bpm);

                bpm_history.unshift(bpm);
                bpm_history = bpm_history.slice(0, 6);
                let sumLength = bpm_history.length;

                let average5BPM = 0;
                for (let beat of bpm_history) {
                    average5BPM = average5BPM + beat / sumLength;
                }

                let averageMinus = 0.9 * average5BPM;
                let averagePlus = 1.1 * average5BPM;
                let accurate = true;
                for (let beat of bpm_history) {
                    if (beat < averageMinus) {
                        console.log('Outside Low Range', bpm);
                        console.log('Average Minus', averageMinus);
                        accurate = false;
                    }
                    if (beat > averagePlus) {
                        console.log('Outside High Range', bpm);
                        console.log('Average Plus', averagePlus);
                        accurate = false;
                    }
                }

                console.log('Average BPM', average5BPM);

                this.setState(
                    { tic: toc, accurate: accurate, bpm_history: bpm_history },
                    this.update(Math.round(average5BPM)),
                );
            } else {
                this.setState({ tic: Date.now() });
            }
        }
    };

    render() {
        let value = this.props.value ? this.props.value : 'N/A';

        let status_message = null;
        if (this.state.tic) {
            status_message = (
                <span
                    style={{ fontSize: '2rem' }}
                    className="badge text-bg-dark"
                >
                    Not Enough Data
                </span>
            );
            if (this.state.bpm_history.length > 2) {
                if (this.state.accurate) {
                    status_message = (
                        <span
                            style={{ fontSize: '2rem' }}
                            className="badge text-bg-success"
                        >
                            Accurate
                        </span>
                    );
                } else {
                    status_message = (
                        <span
                            style={{ fontSize: '2rem' }}
                            className="badge text-bg-danger"
                        >
                            Not Accurate
                        </span>
                    );
                }
            }
        }

        let content = (
            <div id="standard-bpm-display">
                <h1 class="display-4">
                    <span>{value} BPM.</span>
                    {status_message}
                </h1>
                <p class="lead">
                    To calculate Beats Per Minute, just start hitting ctrl at
                    any time. Additionally, we keep track of the accuracy of
                    your bpm. That's done by making sure you are consistent over
                    the last few beats.
                </p>
                <hr class="my-4" />
                <Button
                    onClick={(event) => this.update(this.props.value * 2)}
                    type="info"
                    style={{ marginRight: '4px' }}
                >
                    Double BPM
                </Button>
                <Button
                    onClick={(event) => this.update(this.props.value * 0.5)}
                    type="info"
                >
                    Half BPM
                </Button>
                <br />
                <br />
                <Button
                    onClick={(event) => this.update(null)}
                    type="danger"
                    style={{ marginRight: '4px' }}
                >
                    Clear BPM
                </Button>
                <Button
                    onClick={(event) => this.setState({ manual: true })}
                    type="warning"
                >
                    Manually Enter BPM (Not Recommended)
                </Button>
            </div>
        );

        if (this.state.manual) {
            content = (
                <div>
                    <h1 class="display-4">{`${value} BPM.`}</h1>
                    <TextInput
                        type="number"
                        placeholder="Enter BPM"
                        handle_change={(event) =>
                            this.update(event.target.value)
                        }
                        value={this.props.value}
                    />
                </div>
            );
        }
        return (
            <div>
                <div class="page-header">
                    <h3>
                        <a class="anchor" name="newBPM"></a>BPM
                    </h3>
                </div>

                {content}
            </div>
        );
    }
}

import React, { Component } from 'react';

import { Form, TextInput, Container, Loading, Select, MultiSelect } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class ManageClient extends Component {
    constructor(props) {
        super(props);

        let params = get_global_state()['params'];
        let url = params['id']
            ? `/api/home/client/${params['id']}/`
            : `/api/home/client/`;

        this.state = {
            url: url,
            loaded: false,
            data: { ml_models: [] }
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'GET',
            this.state.url,
            {},
            function (value) {
                if (!('owner' in value)) {
                    let user = get_global_state()['user'];
                    value['owner'] = user.id;
                }
                this.setState({ loaded: true, data: value });
            }.bind(this),
        );
    }

    render() {
        let params = get_global_state()['params'];

        let ml_models = [];
        for (let model of this.state.data.ml_models) {
            ml_models.push(model.id);
        }
        console.log('ML MODELS:  ', ml_models);

        // Limit defaults to editable values only
        let defaults = {
            name: this.state.data['name'],
            taxonomy: this.state.data['taxonomy'],
            ml_models: ml_models
        };

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <Form
                        defaults={defaults}
                        submit_url={this.state.url}
                        redirect={function () {
                            window.location.href = '/admin/home/client/';
                        }}
                    >
                        <TextInput name="name" label="Name" />
                        <Select
                            name="taxonomy"
                            label="Taxonomy"
                            options_url="/api/home/taxonomy/"
                        />
                        <MultiSelect
                            name="ml_models"
                            label="ML Models:"
                            options_url="/api/home/mlmodel/"
                        />

                    </Form>
                </Loading>
            </Container>
        );
    }
}

import {
    AnonRoutes,
    AuthedRoutes,
    AdminRoutes,
    Login,
    Logout,
    Project,
    Song,
    Projects,
    ReviewDropboxSyncs,
    DropboxSync,
    ImportTaxonomy,
    ManageTaxonomy,
    ManageClient,
    ManageProject,
    ObjectList,
    AdminDashboard,
    CreateSync,
    LoadLastURL,
    ManageExportFormat,
    ManageUILayout,
    ManageMLModel,
    TaggerManager,
    MLManager
} from 'pages';

const routes = [
    {
        element: <AnonRoutes />,
        children: [
            {
                path: 'login',
                element: <Login />,
            },
            {
                path: 'logout',
                element: <Logout />,
            },
            {
                path: 'load_last_url',
                element: <LoadLastURL />,
            },
        ],
    },
    {
        path: '',
        element: <AuthedRoutes />,
        children: [
            {
                index: true,
                element: <Projects />,
            },
            {
                path: 'project/:id',
                element: <Project />,
            },
            {
                path: 'project/:id/add_songs',
                element: <CreateSync />,
            },
            {
                path: 'song/:id',
                element: <Song />,
            },
            {
                path: 'review_dropbox_syncs/:project_id?',
                element: <ReviewDropboxSyncs />,
            },
            {
                path: 'dropbox_sync/:id',
                element: <DropboxSync />,
            },
        ],
    },
    {
        path: 'admin',
        element: <AdminRoutes />,
        children: [
            {
                index: true,
                element: <AdminDashboard />,
            },

            {
                path: 'manage_taggers',
                element: <TaggerManager />,
            },
            // {
            //     path: 'manage_clients',
            //     element: <ManageClients />
            // },
            // {
            //     path: 'manage_taxonomy',
            //     element: <ManageTaxonomy />
            // },
            // {
            //     path: 'manage_users',
            //     element: <ManageUsers />
            // },
            {
                path: 'manage_ml',
                element: <MLManager />
            },
            // {
            //     path: 'manage_users',
            //     element: <ManageUsers />
            // },

            {
                path: 'import_taxonomy',
                element: <ImportTaxonomy />,
            },
            {
                path: 'taxonomy/:id?',
                element: <ManageTaxonomy />,
            },
            {
                path: 'exportformat/:id?',
                element: <ManageExportFormat />,
            },
            {
                path: 'uilayout/:id?',
                element: <ManageUILayout />,
            },
            {
                path: 'mlmodel/:id?',
                element: <ManageMLModel />,
            },
            {
                path: 'client/:id?',
                element: <ManageClient />,
            },
            {
                path: 'project/:id?',
                element: <ManageProject />,
            },
            {
                path: ':app/:modelname',
                element: <ObjectList />,
            },
        ],
    },
];

export default routes;
